import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { UserContext } from '/src/contexts/UserContext'

const AppointmentPicker = loadable(() => import('/src/components/Complex/AppointmentPicker'))
const TrustAppointmentForm = loadable(() => import('/src/forms/TrustAppointmentForm'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const AppointmentPickerBlock = ({ backgroundColour, size, titleArea, collectionId }) => {
    const [user] = useContext(UserContext)

    // APPOINTMENT TYPES KEY (COLLECTION ID)
    const appointmentTypes = {
        2073: {
            // Default Sales
            name: 'sales',
            duration: 60
        },
        2222: {
            // CC Callbacks
            name: 'ccCallbacks',
            duration: 60
        },
        2273: {
            // Team EB
            name: 'teamEb',
            duration: 30
        },
        2286: {
            // SCT
            name: 'sct',
            duration: 60
        },
        2221: {
            // SCP
            name: 'scp',
            duration: 60
        },
        2092: {
            // Trusts
            name: 'trusts',
            duration: 15
        },
        2256: {
            // Voucher
            name: 'voucher',
            duration: 60
        },
        2371: {
            // Free Will Promo
            name: 'freeWill',
            duration: 60
        },
        2384: {
            // CIC
            name: 'cic',
            duration: 30
        },
        2411: {
            // Veriform Prequalified
            name: 'veriform',
            duration: 60
        }
    }

    const collectionIdentifier = Number(collectionId)
    let appointmentType
    let appointmentDuration

    for (const [key, value] of Object.entries(appointmentTypes)) {
        const id = Number(key)

        if (id === collectionIdentifier) {
            appointmentType = value.name
            appointmentDuration = value.duration
        }
    }

    // Lead ID object with two types of lead IDs
    const leadId = {
        plid: user.plid,
        lid: user.lid
    }

    return (
        <Section id={'form'} variant={backgroundColour} size={size} titleArea={titleArea}>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    {appointmentType === 'trusts' ? ( // If Trusts
                        <TrustAppointmentForm
                            leadId={leadId}
                            collectionId={collectionIdentifier}
                            appointmentDuration={appointmentDuration}
                        />
                    ) : (
                        <AppointmentPicker
                            leadId={leadId}
                            collectionId={collectionIdentifier}
                            appointmentDuration={appointmentDuration}
                        />
                    )}
                </div>
            </div>
        </Section>
    )
}

AppointmentPickerBlock.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']),
    // Optional section size if need to be passed in from external component - overrides existing value
    size: PropTypes.oneOf(['reg', 'sm', 'ty']),
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    collectionId: PropTypes.oneOf(['2073', '2222', '2273', '2286', '2221', '2092', '2256', '2371', '2384', '2411'])
}

AppointmentPickerBlock.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        title: 'Get your FREE quote today',
        description: `<p>Choose a time slot, and we'll give you a call back!</p>`
    },
    collectionId: '2073'
}

export default AppointmentPickerBlock

export const query = graphql`
    fragment AppointmentPickerBlockForPageBuilder on WpPage_Acfpagebuilder_Layouts_AppointmentPickerBlock {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        collectionId
    }
`
